@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

:root {

  --video-bg-color: rgba( 0, 83, 161, 1 );
  --video-bg-color-transparent: rgba( 0, 83, 161,0 );

}


body {
  margin: 0;
  font-family: 'Poppins', 'Roboto',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.bottom_panel
{

  flex: 1;
  position: relative;
}


.background_image
{
  position: fixed;
  background-image: url('https://cdn.telezing.com/web/img/app_background_light.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
}

.top_panel
{

  width: 100%;
  height: 50vh;
  max-height: 50vh;
  position: relative;
}

.top_panel_background
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var( --video-bg-color);
  opacity: 1;
  animation: fadeInBackground 10000ms;
  animation-fill-mode: forwards;
}

.panel_fade
{
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 8px;
   background: linear-gradient(180deg, var( --video-bg-color) 0%, var( --video-bg-color-transparent ) 100%);
   animation: fadeInBackground 10000ms;
   animation-fill-mode: forwards;
}

.intro_logo_holder {
position: absolute;
left: 0;
top:0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.footer
{
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - 28px);
  width: 100%;
  padding: 3px;
  font-size: 14px;
}

.video_holder {
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 1200ms;
  }

.intro_logo
{
  width: 100%;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://fst.fabtale.com/tz/web/telezing-logo-white.svg');
  opacity: 0;
  transform: scale(0.9);
  transition: all 1200ms;
}

.video
{
  max-width: 100%;
  height: 100%;

}



.info {
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0;
  animation: fadeInInfo 1000ms linear 3000ms;
  animation-fill-mode: forwards;
}

.info_top
{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: top;
  gap: 30px;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;

}

.info_logo {
  width: 150px;
  height: 80px;
  min-width: 150px;
  min-height: 80px;
  background-image: url('https://fst.fabtale.com/tz/web/telezing-logo-black.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.info_text
{
  text-align: left;
  xtext-wrap: balance;
  font-size: 110%;
}

.info_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px;
  padding-bottom: 30px;
  text-wrap: balance;
}

.button {
  text-decoration: none;
  text-transform: uppercase;
  background-color: var( --video-bg-color );
  color: white;
  cursor: pointer;
  padding: 7px 14px;
  display: inline-block;
  border-radius: 5px;
  z-index: 1;
}


.sign_in
{
  position: absolute;
  top: 3px;
  right: 10px;
  color: white;
  z-index: 1;
  text-decoration: none;
  font-size: 90%;
}

.sign_in:active {
  opacity: 0.7;
}

@keyframes fadeInInfo {
  0% { opacity: 0; translate: 0 8px 0;  }
  100% { opacity: 1; translate: 0 0 0;  }
}

@keyframes fadeInBackground {
  0% { opacity: 0.3;   }
  100% { opacity: 1;  }
}

@media (orientation: portrait) {

  .top_panel {
    height: calc(100vw / 1.77777 );;
  }

  .info_logo
  {
     width: 80px;
     max-width: 80px;
     min-width: 80px;
  }
  .info_top
  {
    padding: 10px;
    gap: 10px;

  }

  .info_text {

    font-size: 105%;
  }

  .info {
    gap: 10px;
  }

}